import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconClone: FC<IconType> = ({ className, color, onClick, size = 16 }) => (
  <svg
    className={className}
    height={size}
    onClick={onClick}
    viewBox="0 0 512 512"
    width={size}
  >
    <path
      d="M464 0c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48H176c-26.51 0-48-21.49-48-48V48c0-26.51 21.49-48 48-48h288M176 416c-44.112 0-80-35.888-80-80V128H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-48H176z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconClone;
