import { DocumentType, EspaceType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { EspaceData, PathnameData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Item from '~/components/List/Item/Pathname';
import List from '~/containers/Espace/List';
import params from '~/params/espace.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const AdminEspacePathnames: FC<PageProps & UserProps> = ({
  location,
  user,
  params: { id: espaceId },
}) => {
  const [espace, setEspace] = useState<DocumentType<EspaceType>>();

  useEffect(() => {
    let isMounted = true;
    const espaceData = new EspaceData();
    espaceData
      .findById(espaceId)
      .then(snapshot => {
        if (isMounted) {
          setEspace(snapshot);
        }

        return isMounted;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  return (
    <TemplateAdmin>
      <List
        filters={compact([
          {
            label: 'Type de document',
            name: 'collectionName',
            options: [
              {
                label: 'Article',
                value: 'articles',
              },
              {
                label: 'Mémo',
                value: 'memos',
              },
              {
                label: 'Page',
                value: 'pages',
              },
              {
                label: 'Produit',
                value: 'produits',
              },
              {
                label: 'Document',
                value: 'documents',
              },
            ],
          },
        ])}
        itemList={Item}
        model={new PathnameData({ espaceId, params })}
        search={location.search}
        title={`Chemin de l'espace ${espace?.label ?? espaceId}`}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(AdminEspacePathnames, 'admin');
